/*================================================================================
	Item Name: LV Crematory 
	Version: 2.0
	Author: Ramiz Khan
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

// @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,900;1,300;1,400;1,500;1,700;1,900&display=swap");

// body {
//   font-family: "Roboto", sans-serif !important;
// }

iframe {
  display: none !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-900 {
  font-weight: 900 !important;
}
.form-label {
  font-weight: 600;
}
.form-check-label.form-label {
  font-weight: 500;
}
// [dir] .card-header {
//     padding: 0.5rem 1.5rem !important;
// }
.btn-warning {
  color: #000000 !important;
}
.min-width-9 {
  min-width: 9rem !important;
}
.min-width-11 {
  min-width: 11rem !important;
}
.border-heading {
  border-bottom: 2px dashed #d8d2d2;
  padding-bottom: 0.35rem;
  display: inline-block;
}
// .resetPasswordWrapper .auth-inner{
// 	max-width: 500px !important;
// }
.auth-inner-payment-verified {
  max-width: 800px;
  width: 100%;
}
.password-tips li {
  list-style: none;
}
.mx-w-100 {
  max-width: 100px;
  width: 100%;
}
.max-w-300 {
  max-width: 300px;
  width: 100%;
}
body.swal2-height-auto {
  height: 100% !important;
}
// [dir=ltr] .pagination-sm .page-item:first-child .page-link {
//     border-top-left-radius: 50%;
//     border-bottom-left-radius: 50%;
// }
// [dir=ltr] .pagination-sm .page-item:last-child .page-link {
//     border-top-right-radius: 50%;
//     border-bottom-right-radius: 50%;
// }
.swal2-cancel {
  margin-left: 1rem;
}
.dataTable-filter {
  max-width: 245px !important;
  min-width: 245px !important;
}
@media only screen and (max-width: 768px) {
  .dataTable-filter {
    max-width: 185px !important;
    min-width: 185px !important;
  }
}
.fFGrAy,
.izzqyT {
  min-height: auto !important;
}
.react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  height: auto !important;
}
[dir] .react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  padding: 0.5rem 0.5rem !important;
}
[dir] .react-dataTable .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol {
  padding: 0.72rem 0.5rem !important;
}
[dir="ltr"] .modal .modal-header .btn-close:hover,
[dir="ltr"] .modal .modal-header .btn-close:focus,
[dir="ltr"] .modal .modal-header .btn-close:active {
  transform: translate(18px, -10px) !important;
}
[dir] .modal .modal-fullscreen .modal-header .btn-close:hover {
  transform: translate(0, 0) !important;
}
.dropzone {
  min-height: 150px !important;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: auto;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

[dir="ltr"] legend {
  float: inherit;
}
.sc-fzoLsD .avatar-content {
  text-transform: uppercase;
}
.react-tel-input .form-control {
  width: 100% !important;
}

.AddPatientStepper .bs-stepper {
  box-shadow: none;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: #003c71 !important;
}
.patientBasicInfoatPatientDetail.table > :not(caption) > * > * {
  padding: 0.5rem 0rem !important;
}

.bIYKQe {
  padding: 4px 16px 4px 16px !important;
  background-color: transparent !important;
}
.matchedColumn {
  background: rgb(116 211 112 / 27%);
}
.min-width-13 {
  min-width: 13rem;
}
.prescriptionPrintFooter {
  font-size: 0.9rem;
}
[dir] #prescriptionPrintArea .table > :not(caption) > * > * {
  border-bottom-width: 0px;
}

[dir] #prescriptionPrintArea .table > :not(caption) > * > * {
  padding: 0.2rem 0.3rem;
}

@media print {
  #root {
    display: none;
  }
  #prescriptionPrintArea {
    display: block !important;
    background-color: white;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    padding: 1px;
    font-size: 14px;
    line-height: 15px;

    .print-footer {
      // height: 100%;
      // width: 100%;
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      margin: 0;
      // padding: 15px;
      // font-size: 14px;
      // line-height: 18px;
    }
  }
}

#background {
  position: absolute;
  z-index: 0;
  background: white;
  display: block;
  min-height: 50%;
  min-width: 50%;
  color: yellow;
}

.no-min-height {
  min-height: calc(var(--vh, 1vh) * 90) !important;
}

// new style for LV
/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #d0cfcf #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #d0cfcf;
  border-radius: 10px;
  border: 3px solid #ffffff;
}
.login-img {
  // background-image: url('../images/backgrounds/cremation-urn.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.header__center {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-column-gap: 1.2rem;
  align-items: center;
}

.header__center::before,
.header__center::after {
  content: "";
  display: block;
  height: 1px;
  background-color: #000040;
}
.modal-content {
  border-left: 8px solid #000040;
}
// .deceased-table .react-dataTable .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol,
// .deceased-table .react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
//   padding: 0.72rem 0.5rem ;
// }
// .deceased-table .react-dataTable .rdt_TableHead .rdt_TableHeadRow {
//   min-height: 0;
//   height: auto;
// }
// .deceased-table .kmbVPi {
//   overflow: hidden;
//   white-space: break-spaces;
//   text-overflow: inherit;
// }
.react-dataTable .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol,
.react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  padding: 0.72rem 0.5rem;
}
.react-dataTable .rdt_TableHead .rdt_TableHeadRow {
  min-height: 0;
  height: auto !important;
}
.kmbVPi {
  overflow: hidden;
  white-space: break-spaces !important;
  text-overflow: inherit !important;
}
.qr-code-style {
  text-align: center;
}
.payment-info-cs {
  position: relative;
  top: -14px;
  padding: 0 24px;
}
.user_profile_img {
  border: 1px solid;
  width: fit-content;
  border-radius: 50%;
  padding: 4px;
}
.user_profile_img img {
  border-radius: 50%;
  object-fit: cover;
}
.bs-stepper {
  background-color: #fff;
  box-shadow: none !important;
  border-radius: 0.5rem;
}
.radio-with-images {
  justify-content: center !important;
  align-items: stretch;
}
.radio-with-images .form-check .form-check-input {
  text-align: center;

  margin-left: 0;
}
.radio-with-images .form-check {
  min-height: 230px;
  // height: 100%;
  max-width: 210px;
  width: 100%;
  margin-bottom: 0.8rem;
  // flex: 1;
}
.radio-with-images .form-check label {
  width: 100%;
  // flex: 1;
}
.radio-img {
  width: 100%;
  text-align: center;
  background-color: #eeeeee;
  padding: 4px;
}
.form-radio-deceased-cs img {
  width: 100%;
  height: 120px;
  object-fit: contain;
}
.granite-collection-lab {
  font-size: 18px;
}
.list-style-none {
  list-style: none;
}
.bg-secondary {
  color: #000040;
}
.upcoming-drop-off-listing li {
  border: 1px solid #ebe9f1;
  padding: 8px;
  margin-bottom: 8px;
}
.upcoming-drop-off-listing li:last-child {
  margin-bottom: 0px;
}
.react-calendar {
  border: 1px solid #ebe9f1;
}
.react-calendar__tile--active {
  background: #000040 !important;
}
.text-capilize {
  text-transform: capitalize;
}
.cs-width-toggle.form-switch .form-check-input {
  width: 83px !important;
}
.cs-width-toggle .switch-icon-right,
.cs-width-toggle .switch-icon-left {
  margin-top: 1px;
  color: #fff !important;
}
.form-switch.cs-width-toggle .form-check-input:not(:checked) {
  background-color: #dc3545;
  color: #fff !important;
}
.flatpickr-calendar.open {
  padding-bottom: 8px !important;
}
// table height
// .dVgdrY{
//   min-height: 320px !important;
// }
.rct-node-icon .rct-icon {
  color: #06003e;
}
.popover {
  min-width: 250px;
}
.popover-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn-flat-white {
  color: #fff;
}
.disabledbuttonCs {
  pointer-events: none;
  // opacity: 0.4;
}
.react-dataTable > div:first-of-type {
  min-height: 320px !important;
}
.text-dark-cs-cremationremains {
  color: #000000 !important;
}
.p-relative {
  position: relative;
}
.checkbox-style-p {
  position: absolute;
  top: 40px;
}
// calendar style
.fc-daygrid-dot-event .fc-event-title {
  overflow: unset !important;
  word-break: break-all;
  font-size: 10px;
}
.fc .fc-daygrid-event-harness .fc-event {
  display: flex;
  align-items: baseline;
}
.fc-daygrid-event {
  white-space: inherit !important;
}
.fc .fc-timegrid .fc-timegrid-slots .fc-timegrid-slot {
  height: 4rem !important;
}
@media screen and (max-width: 768px) {
  .checkbox-style-p {
    top: 0px;
  }
}
.qa-code-table {
}
.file-name-text {
  width: 50px;
}
.file-name-title {
  word-break: break-all;
}
@media only screen and (max-width: 576px) {
  .qa-code-table.table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
  }
}
@media only screen and (max-width: 480px) {
  .qa-code-table.table > :not(caption) > * > * {
    padding: 0.5rem 0.2rem;
  }
  .file-name-title {
    font-size: 10px;
    line-height: 14px;
  }
  .file-name-text {
    width: 40px;
    font-size: 10px;
  }
  .qrCode-box {
    width: 200px !important;
    height: 200px !important;
  }
}
.icon-width {
  width: 20px;
}
.modern-nav-toggle {
  display: flex !important;
  border: 1px solid #000040;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  margin: 1.2rem 0;
}
.brand-text-funeral {
  // color: #000040;
  font-weight: 600;
  letter-spacing: 0.01rem;
  font-size: 1.45rem;
  text-transform: capitalize;
}
.brand-text-funeral-right {
  // color: #000040;
  font-weight: 600;
  letter-spacing: 0.01rem;
  font-size: 1rem;
  text-transform: capitalize;
}
.main-menu .navbar-header .navbar-brand {
  margin-top: 0rem;
}
.main-menu .navbar-header .navbar-brand .brand-logo img {
  max-width: 140px;
}
.funeral-home-text-with-logo {
  font-size: 12px;
  font-weight: 600;
}
.main-menu .navbar-header {
  height: auto;
}
.main-menu .navbar-header .navbar-brand .brand-logo img {
  margin-top: 6px;
  padding-bottom: 6px;
  margin-bottom: 6px;
  border-bottom: 1px solid #adadad;
}
.header-navbar .navbar-container ul.navbar-nav li .media-list {
  // max-height: 25rem;
  overflow-y: auto !important;
}
@media only screen and (max-width: 575px) {
  .card .card-header {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}
.card {
  height: calc(100% - 2rem);
}
.fmTGrY div:first-child {
  white-space: unset !important;
  overflow: unset !important;
  text-overflow: unset !important;
}
.min-width-200 {
  min-width: 200px !important;
}
.gYZcdg div:first-child {
  white-space: unset !important;
  overflow: unset !important;
  text-overflow: unset !important;
}
.input-height-alignment {
  height: 80p;
}
.heading-name {
  min-width: 115px;
  padding-right: 4px;
}
.heading-desc {
  min-width: 210px;
  padding-right: 4px;
}
.label-cut-dots {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.form-radio-deceased-cs {
  cursor: pointer;
}

.fc-more-popover {
  max-height: 95%;
  overflow-y: auto;
}
